

@font-face {
  font-family: 'GTAmerica';
  src: url(./GT-Planar-Light-Trial.woff2);
}

* {
  font-family: "GTAmerica";

  font-weight: bold;
}




.projectImageThumbnail {
  max-width: 100%;
  position: relative;
  margin-top: -200%;
  margin-right: -50%;
  float: right;
  max-height: 100vh;
  filter: blur(8px);
}

.cursor {
  width: 100%;
  height: 100vh;
}

.right-column {
  text-align: center;
}

.cursor-coordinates {
  text-align: center;
  font-size: 7vw;
  -webkit-text-stroke: 0.2vw black;
  color: transparent;
  text-decoration: none;
  z-index: 3;
  position: relative;
}

.header-title {
  font-size: 7vw;
  -webkit-text-stroke: 0.2vw black;
  color: transparent;
  text-decoration: none;
  display: block;
  transition: all 0.2s ease-in-out;
  animation: wubble 5s ease-in-out infinite alternate-reverse;
}

@keyframes wubble {
  from {
    transform: rotate(-5deg) scaleY(1.2) scaleX(1);
  }
  to {
    transform: rotate(5deg) scaleY(1) scaleX(1.2);
  }
}

.description {
  font-size: 32px;
  font-weight: lighter;
  position: relative;

  z-index: 3;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  .cursor-coordinates {
    visibility: hidden;
  }
}
.item-title {
  font-size: 4rem;
  padding: 0px;
  transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  opacity: 1;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  z-index: 3;
}
@media only screen and (max-width: 425px) {
  .item-title {
    font-size: 2rem;
    padding: 0px;
    transition: opacity 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
    opacity: 1;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    z-index: 3;
  }
}

.portfolio-titles ul li {
  list-style: none;
}

.item-title:hover {
  cursor: pointer;
  animation-name: changeColor;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes changeColor {
  from {
    color: black;
  }
  to {
    color: red;
  }
}
